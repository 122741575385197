import {Component} from '@angular/core'
import version from '../assets/package.json'
import {ConfigService} from './services/config.service'
import {Router} from '@angular/router'
import {environment} from '../environments/environment'
import {switchMap} from 'rxjs'
import {SingleSignOnService} from '@sparbanken-syd/sparbanken-syd-bankid'

@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(
    private router: Router,
    private singleSignOnService: SingleSignOnService,
    public configService: ConfigService) {
  }

  title = 'id-admin-app'
  public version = version.version

  public logout(logout: boolean): void {
    if (logout) {
      this.singleSignOnService.deleteToken(environment.authServiceUrl).pipe(
        switchMap(() => this.configService.resetToken()))
      .subscribe({
        next: () => this.router.navigate(['login'])
      })
    }
  }
}
