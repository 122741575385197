import {Injectable} from '@angular/core'
import {map, Observable} from 'rxjs'
import {SparbankenUser} from '@sparbanken-syd/sparbanken-syd-bankid'
import {environment} from '../../environments/environment'
import {HttpClient} from '@angular/common/http'

@Injectable({
	providedIn: 'root'
})
export class UserService {
	constructor(private httpClient: HttpClient) {
	}

	public getUsers(): Observable<SparbankenUser[]> {
		const url = `${environment.authServiceUrl}/users`
		return this.httpClient.get<SparbankenUser[]>(url)
	}

	/**
	 * Retrieves all offices of the Sparbanken.
	 */
	public getOffices(): Observable<string[]> {
		const url = `${environment.authServiceUrl}/data/offices`
		return this.httpClient.get<string[]>(url)
		.pipe(
			map(offices => offices.sort())
		)
	}

}
