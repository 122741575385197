<div class="spb-holder">
  <spb-header></spb-header>
  <div class="spb-plain-content">
    <router-outlet></router-outlet>
  </div>
  <div class="spb-filler"></div>
  <spb-footer
          [token]="(configService.configState$ | async)?.token || ''"
          [license]="true"
          [version]="version"
          copyrightYears="2023"
          (logoutUser)="logout($event)"
  ></spb-footer>
</div>
