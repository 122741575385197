<div class="holder">
  <div class="text-container mat-elevation-z8 ">
    <p>Välkommen till administrations-tjänsten för verifiering av digitalt ID-kort. För att kunna använda tjänsten
      behöver du först identifiera dig med BankID.</p>
    <spb-bankid (accessToken)="configService.setToken($event)"
                [bankIdUrl]="bankIdUrl"
                [domain]="domain"
    ></spb-bankid>
  </div>
</div>
<div class="spb-filler"></div>
