{
  "name": "id-admin-app",
  "version": "0.1.10-09f29e8c.0",
  "scripts": {
    "watch": "ng test",
    "build": "./scripts/version.sh && ng build",
    "install": "cp ./package.json ./src/assets",
    "lint": "ng lint",
    "ng": "ng",
    "start": "cp ./package.json ./src/assets && ng serve --ssl --host localhost.sparbanken.me",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix",
    "preinstall": "./scripts/configure.sh",
    "test": "ng test --watch=false --code-coverage"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.2",
    "@angular/common": "^17.0.2",
    "@angular/compiler": "^17.0.2",
    "@angular/core": "^17.0.2",
    "@angular/forms": "^17.0.2",
    "@angular/material": "^17.0.0",
    "@angular/platform-browser": "^17.0.2",
    "@angular/platform-browser-dynamic": "^17.0.2",
    "@angular/router": "^17.0.2",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.0",
    "@angular-eslint/builder": "^17.0.1",
    "@angular-eslint/schematics": "^17.0.1",
    "@angular-eslint/template-parser": "^17.0.1",
    "@angular/cdk": "^17.0.0",
    "@angular/cli": "^17.0.0",
    "@angular/compiler-cli": "^17.0.2",
    "@sparbanken-syd/personnummer": "^2.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^17.0.0",
    "@sparbanken-syd/sparbanken-syd-qr": "^17.0.1",
    "@sparbanken-syd/sparbanken-syd-theme": "^17.0.2",
    "@types/jasmine": "~5.1.1",
    "@types/node": "^20.8.9",
    "@types/sparbanken-syd-bil": "^0.0.6",
    "@typescript-eslint/eslint-plugin": "^6.10.0",
    "@typescript-eslint/parser": "^6.10.0",
    "eslint": "^8.53.0",
    "eslint-plugin-import": "2.29.0",
    "eslint-plugin-jsdoc": "^46.8.2",
    "eslint-plugin-prefer-arrow": "1.2.3",
    "jasmine-core": "^5.1.1",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "postcss": "^8.4.31",
    "postcss-scss": "^4.0.9",
    "stylelint": "^15.11.0",
    "stylelint-config-standard-scss": "^11.0.0",
    "stylelint-scss": "^5.2.1",
    "typescript": "~5.2.2"
  }
}
