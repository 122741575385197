import {Injectable} from '@angular/core'
import {first, Observable, switchMap, tap} from 'rxjs'
import {HttpClient} from '@angular/common/http'
import {environment} from '../../environments/environment'
import {BankIDVerificationData} from 'sparbanken-syd-bil'
import {ConfigService, SpbConfiguration} from './config.service'


@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient
  ) {
  }

  public getAllTransactions(): Observable<BankIDVerificationData[]> {
    let url = `${environment.bilAPIUrl}/admin` // Default URL

    return this.configService.configState$.pipe(
      first((config: SpbConfiguration) => config.idAdmin || config.idUser),
      tap((config: SpbConfiguration) => {
        if (config.idAdmin) {
          url = `${environment.bilAPIUrl}/admin` // Uppdaterar URL:en om isAdmin är true
        } else if (config.idUser) {
          url = `${environment.bilAPIUrl}/user` // Uppdaterar URL:en om idUser är true
        }
      }),
      switchMap(() => this.httpClient.get<BankIDVerificationData[]>(url))
    )
  }
}
