<div>
  <div class="holder">
    <div class="middle">
      <div class="logo">
        Digitalt ID-kort Admin
      </div>
      <div *ngIf="ready" class="middle">
        <div class="spb-filler"></div>
        @if (user) {
          <span>{{ user.name }}</span>
        }
        @if (configService.configState$ | async; as config) {
          <div class="middle-menu">
            @if (config.ready) {
              <div>
                <button [matMenuTriggerFor]="menu" mat-button>
                  <mat-icon>menu</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button (click)="logout()" mat-menu-item>Logga ut</button>
                </mat-menu>
              </div>
            }
          </div>
        }
      </div>
    </div>
  </div>
</div>
