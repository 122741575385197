import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {MatMenuModule} from '@angular/material/menu'
import {BankIdModule} from '@sparbanken-syd/sparbanken-syd-bankid'
import {MatSortModule} from '@angular/material/sort'
import {MatTableModule} from '@angular/material/table'
import {MatInputModule} from '@angular/material/input'
import {ReactiveFormsModule} from '@angular/forms'
import {MatDialogModule} from '@angular/material/dialog'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {MatTabsModule} from '@angular/material/tabs'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {LoginComponent} from './login/login.component'
import {IdListComponent} from './id-list/id-list.component'
import {MatIconModule} from '@angular/material/icon'
import {SpbPaginatorSv, ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {MatButtonModule} from '@angular/material/button'
import {HeaderComponent} from './common/header/header.component'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field"
import {HTTP_INTERCEPTORS} from "@angular/common/http"
import {AuthInterceptor} from "./application/auth-interceptor"
import {WINDOW_PROVIDERS} from "./application/window.provider"
import {LOCAL_STORAGE_PROVIDERS} from "./application/local-storage.provider"
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator"
import {MAT_DATE_LOCALE} from '@angular/material/core'
import {MatSelectModule} from '@angular/material/select'
import {MatTooltipModule} from '@angular/material/tooltip'
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner"

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    IdListComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    AppRoutingModule,
    ThemeModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    BankIdModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatSelectModule,
    MatTooltipModule
  ], providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'outline'}
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {floatLabel: 'always', hideRequiredMarker: true, appearance: 'outline'}
    },
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},
    {provide: MatPaginatorIntl, useClass: SpbPaginatorSv},
    WINDOW_PROVIDERS,
    LOCAL_STORAGE_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
