import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core'
import {Router} from '@angular/router'
import {ConfigService, SpbConfiguration} from '../../services/config.service'
import {catchError, EMPTY, Subscription, switchMap} from 'rxjs'
import {filter} from 'rxjs/operators'
import {SingleSignOnService, SparbankenUser} from '@sparbanken-syd/sparbanken-syd-bankid'
import {LOGIN_ROUTE_PATH} from '../../application/data-types'
import {environment} from '../../../environments/environment'
import {WINDOW} from '../../application/window.provider'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  public bankIdUrl = environment.authServiceUrl

  /**
   * The current user.
   */
  public user: SparbankenUser = {itemId: '', roles: [], sId: '', sub: '', name: ''} as any

  /**
   * Hide the menu etc. if not logged in at all.
   */
  public ready = false

  /**
   * Hide the menu from non
   */
  public isIdAdmin = false
  public isidUser = false

  /**
   * If the user can install the app
   */
  public canInstall = false

  /**
   * Holds the subscription of config and currentUser.
   */
  private config$ = new Subscription()
  private currentUser$ = new Subscription()

  private deferredPrompt: any

  constructor(
    @Inject(WINDOW) private window: Window,
    public configService: ConfigService,
    private router: Router,
    private singleSignOnService: SingleSignOnService
  ) {
  }

  public ngOnInit(): void {
    this.config$ = this.configService.configState$
      .pipe(
        filter((config: SpbConfiguration) => config.ready)
      )
      .subscribe({
        next: (config: SpbConfiguration) => {
          this.ready = config.ready
          this.isIdAdmin = config.idAdmin
          this.isidUser = config.idUser
        }
      })

    /**
     * Get the current user and give admin rights if the user has the correct role
     */
    this.currentUser$ = this.configService.currentUser$
      .subscribe({
        next: (user: SparbankenUser) => {
          if (user.roles) {
            this.user = user
          }
        }
      })

    this.singleSignOnService.getToken(environment.authServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          this.logout()
          return EMPTY
        })
      )
      .subscribe(
        {next: (accessToken: string) => this.configService.setToken(accessToken)})
  }

  public ngAfterViewInit() {
    // Initialize deferredPrompt for use later to show browser install prompt.
    this.window.addEventListener('beforeinstallprompt', (event: Event) => {
      // Stash the event so it can be triggered later.
      this.deferredPrompt = event
      // Show the install button in the menu
      this.canInstall = true
    })
  }

  public ngOnDestroy(): void {
    this.config$.unsubscribe()
    this.currentUser$.unsubscribe()
  }

  public install(): void {
    // Show the install prompt
    this.deferredPrompt.prompt()
    // We've used the prompt, and can't use it again, throw it away
    this.deferredPrompt = null
  }

  public logout(): void {
    this.singleSignOnService.deleteToken(this.bankIdUrl).pipe(
      switchMap(() => this.configService.resetToken()))
      .subscribe({
        next: () => this.router.navigate([LOGIN_ROUTE_PATH])
      })
  }
}
